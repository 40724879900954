<template>
  <v-container class="grey lighten-4">

    <v-select
      :items="members"
       item-text="BggUserId"
      label="Guild Member"
      outlined
      @input="search"
      v-model="select"
      return-object
      class="px-4 pt-4 "
    ></v-select>
    <v-banner
          single-line
          class="grey lighten-2 mb-2 pt-0"
          elevation="4"
    >
    <span class="text-h6 blue--text text--darken-4"> 
      {{select.BggUserId}} <span class="text-h6 text--primary">{{playerCount}}</span>-Player Games
    </span>
        
    </v-banner>
    <v-banner
          single-line
          class="grey lighten-2  pa-0 ma-0"
          elevation="2"
    >
    <v-slider
        v-model="playerCount"
        min="2"
        max="10"
        @input="search"
        ticks="always"
        color="orange"
        track-color="orange darken-3"
        label="Player Count"
        tabindex="1"
        dense
        class="pa-0 ma-0"
      >
        <!-- class="pt-5" -->
      </v-slider>
    <span class="text-h6 red--text text--darken-4" v-if="games.length == 0"> 
      No Games Found That Play <span class="text-h6 text--primary">{{playerCount}}</span>.
    </span>
    </v-banner>

      
        <!-- label="Player count" -->

    <!-- <v-card-text> -->
      <v-container fluid>
        
       <v-row dense v-for="(game,i) in games"
         :key="i">
         <v-col cols="12">

          <v-card
            color="grey lighten-2" 
            outlined
          >
          <v-card-title class="text-subtitle-1 text--primary pb-2 pt-2 pl-3 font-weight-black">
            <div class="headerClass overflow-visible">
              {{getTitle(game.Name)}}
            </div>
          </v-card-title>

          <v-card-subtitle class="caption pl-3 pb-1">
            <div class="headerClass overflow-visible">
            {{game.Year}} <span  v-if="getSubTitle(game.Name)" >- {{getSubTitle(game.Name)}}</span>
          </div>
          <div></div>
          </v-card-subtitle>

          <v-row>
         <v-col cols="7" class="pt-0 d-flex flex-column" >

          <v-card-text class="pb-0 pt-0 mt-6">
            <!-- <div class="text-h6 text--primary pb-0">
              {{game.Name.split(":")[0]}}<br>
            </div>
            <div class="subheading text--primary pt-0">
              {{game.Name.split(":")[1]}}
            </div> -->
            <div>{{game.MinTime}} - {{game.MaxTime}} Minutes</div>
            <div class="text--primary">
              Plays: <span class="blue--text text--darken-4">{{game.MinPlayers}} </span> to <span class="blue--text text--darken-4">{{game.MaxPlayers}} </span> <br>
              Rating: <span class="font-weight-bold">{{game.AverageRating.toFixed(2)}} </span>({{game.RatingCount}})
            </div>
            
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions>
           <v-btn
              class="ml-2 pt-0"
              outlined
              small
              :href="getBggLink(game.BggGameID)"
              target="_blank"
            >
              BGG Entry
            </v-btn>
          </v-card-actions>

        </v-col>
        <v-col cols="5" class="justify-end d-flex pt-0 pl-2">
            <!-- min-width="120" -->
            <!-- min-height="400px" -->
            <v-sheet
                elevation="4"
                class="grey darken-2 pa-1 mr-3 mb-3 mt-3"
                 height="140"
                 width="140"

            >

          <v-avatar
            size='130'
            tile
            class="pa-0 ma-0 rounded"

          >

          <img 
                class="object-fit: fill"
                :src="game.Thumbnail" 
                :href="getBggLink(game.BggGameID)"
                >
            <!-- <v-img :src="game.Thumbnail" class="background-size:100%;"></v-img> -->
          </v-avatar>
          </v-sheet>
        </v-col>
        </v-row>

        </v-card>   
      </v-col>
    </v-row>
  </v-container>       
    <!-- </v-card-text> -->

   <!--  <v-data-table
      :headers="headers"
      :items="games"
      :items-per-page="20"
      class="elevation-1"
      @click:row="handleRowClick"
      :mobile-breakpoint="0"
    >
       
        <template v-slot:[`item.Thumbnail`]="{ item }">
            <div class="p-2">
              <v-img :src="item.Thumbnail"  max-width="80"></v-img>
            </div>
        </template>
        <template v-slot:[`item.Players`]="{ item }">
            <div class="p-2">
              {{item.MinPlayers}} - {{item.MaxPlayers}}
            </div>
        </template>
        <template v-slot:[`item.PlayTime`]="{ item }">
            <div class="p-2">
              {{item.PlayTime}} Mins
            </div>
        </template>
        <template v-slot:[`item.AverageRating`]="{ item }">
            <div class="p-2">

              {{ item.AverageRating.toFixed(1) }} ({{item.RatingCount}})
            </div>
        </template>

    </v-data-table> -->

  
  <!-- </v-card> -->

  </v-container>
</template>

<script>
  import axios from "axios";
  export default {
    name: 'MainView',

    data: () => ({
        playerCount: 4,
        games: [],
        members:[],
        select:"",
        headers: [
          {text: "Game", value:"Thumbnail", sortable:false},
          { text: 'Name', value: 'Name', align: ' d-none d-lg-table-cell'},
          { text: 'Players', value: 'Players',align: ' d-none d-lg-table-cell'  },
          { text: 'Time', value: 'PlayTime' },
          { text: 'Rating', value: 'AverageRating' },
          { text: 'Year', value: 'Year', align: ' d-none d-lg-table-cell' },
        ],
    }),
    mounted: function(){
        this.getMembers();
        

    },
    methods:{
      getBggLink(id){
        return 'https://boardgamegeek.com/boardgame/'+id;
      },
      getTitle(name){
        return name.split(":")[0]
      },
      getSubTitle(name){
        if (name.split(":").length > 1){
          return name.split(":")[1];
        }
        return false;
      },
      getMembers(){
          axios.get("/api/members").then(
            response => {
              this.members = response.data;
              this.select = response.data[0];
              let obj = this.members.find(o => o.BggUserId === 'cclc_library');
              this.select = obj;
              this.search();
            },
            (error) => {
              console.log(error);
            }
          );
      },
      handleRowClick(row) {
        console.log("going wild", Object.assign({}, row));
        var link = "https://boardgamegeek.com/boardgame/"+row.BggGameID
        window.open(link, '_blank');
      },
      search(){
        axios.get("/api/psearch/"+this.select.ID+"/"+this.playerCount).then(
          response => {
            this.games = response.data;

          },
          (error) => {
            console.log(error);
          }
        );
      }
  },
}
</script>
<style type="text/css">
  .headerClass{
    white-space: nowrap ;
    word-break: normal;
    overflow: hidden ;
    text-overflow: ellipsis;
}
</style>