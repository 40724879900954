<template>
  <v-app>
    <v-app-bar
      app
      color="grey darken-3"
      dark
    >
      <div class="d-flex align-center">
        CCLC Board Games
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://www.facebook.com/groups/centralcoastboardgames"
        target="_blank"
        text
      >
        <span class="mr-2">FB PAGE</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <MainView/>
    </v-main>
  </v-app>
</template>

<script>
import MainView from './components/MainView';

export default {
  name: 'CCLC Boardgames',

  components: {
    MainView,
  },

  data: () => ({
    //
  }),

};
</script>
